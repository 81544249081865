
.programs{
    display: flex;
    flex-direction: column;
    width: 100vwm;
    gap: 2rem;
    padding: 0 2rem;    
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.programs-categories{
    display: flex;
    gap: 1rem; 
}
.category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: gray;
    color: white;
}


.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: aliceblue;
}
.category>:nth-child(2){
    font-size: 20px;
}
.category>:nth-child(3){
    font-size: .8rem;
    line-height: 25px;
}

.category .join-now{
    display: flex;
    /* justify-content: space-between; */
    gap: 5rem;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;
}

.join-now>img{
    width: 1rem;
}

.category:hover{
    background: var(--planCard);
}




@media screen and  (max-width : 768px){
    .programs{
        flex-direction: column;
        transform: scale(0.8);
        margin-top: -13rem;

    }  
    .programs-header{
        flex-direction: column;
        gap: 1rem;
    }
    .programs-categories{
        flex-direction: column;
        margin-left: -1rem;
        transform: scale(0.8);
    }
}