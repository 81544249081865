.header{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.logo{
    width: 10rem;
    height: 10rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}

@media screen and  (max-width : 768px){
   .header{flex-direction: column;}

   /*  دول عشان اعمل الهيدر زي ليست في الجانب ع اليمين*/
   /* البداية */
   /* .header-menu{
    flex-direction: column;
   }

   .header-menu:nth-child(2){
    position: fixed;
    left:  -1rem;
    z-index: 99;
   }
   

   .header>img{
    position: fixed;
    left:  -1rem;
    z-index: 99;
   } */
   /* النهاية */

   .logo{
    /* width: 5rem; */
    transform: scale(0.7);
    margin-top: -2rem;
    }

    .header-menu{
        gap: 0.7rem;
        font-size: small;
        margin-right: 2rem;
        margin-top: -1rem;
    }
}
