.hero{
    display: flex;
    justify-content: space-between; 
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}
.right-h{
    flex: 1;
    background-color: var(--orange);
    position: relative;

}

.the-best-ad{
 background-color: rgba(22, 12, 12, 0.352);
 padding: 1rem;
 width: fit-content;
 border-radius: 4rem;
 color: white;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
    height: 80%;
    width: 5.5rem;
    background-color: var(--orange);
    border-radius: 3rem;
    left: 8px;
    z-index: 1;
}

.header-text{
    display: flex;
    flex-direction: column;
    gap: 1.5;
    font-size: 4.5rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-overflow: inherit;
}
.header-text>div:nth-of-type(3){
    font-size: 1rem;
    color: white;
    font-weight: normal; 
    text-overflow: inherit;
    text-transform:capitalize;
    margin-top: 4rem;
    letter-spacing: 1;
}
.figures{
    display: flex;
    gap: 4.5rem;
}
.figures .fig{
    display: flex;
    flex-direction: column;
    gap:8px;
    font-size: 18px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.77);
}

.figures .fig>div{
    font-weight: bolder;
    font-size: 28px;
    color: white;
}

.buttons{
    display: flex;
    gap: 2rem;
}

.buttons .btn{
    width: fit-content;
    padding: 10px 30px;
    border: 3.5px solid var(--orange);
    color: white;
    font-size: 18px;
}
.buttons .btn.b{
    background-color: var(--orange);
}

.right-h button{
    width: fit-content;
    padding: 7px 20px;
    /* border: 3.5px solid var(--orange); */
    background-color:black;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 2rem;
    right: 2rem;

}
.right-h button:hover{
    background-color: white;
    color: black;
    font-weight: bold;
}
.right-h .heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 20px 30px;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    top: 7rem;
    right: 4rem; 
    align-items: center;
}

.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.hero_image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}
.hero_image_back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    width: 15rem;
    z-index: -1;
}

.calories{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    width: fit-contentx;
    position: absolute;
    top: 40rem;
    right: 31rem;
    padding: 15px 30px;
    font-size: 17px;
}
.calories > img{
    width: 3rem;
}
.calories > div{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-around;
    
}

.hero-blur{
    width: 22rem;
    height: 27rem;
    left: 0;
    top: 5rem;
}

@media screen and  (max-width : 768px){
 .hero{
    flex-direction: column;
    display: flex;
    position: relative;
    align-items: center;
    /* gap: 20rem; */
 }
 .the-best-ad{
    /* margin-left: 2rem; */
    align-self: center;
 }
 
 .the-best-ad>span{
    font-size: 10px;
    /* margin-left: 6rem; */
 }
.header-text{
    transform: scale(.8);
    align-items: center;
    margin-top: 15rem;
    margin-left: 1.5rem;
}
 .header-text>div{
    justify-content: center;
    padding: 0 1rem;
 }

.header-text>div:nth-child(3){
    line-height: 2rem;
    text-align: center;
    text-transform: none;
}

.figures{
    transform: scale(0.8);
    justify-content: center;
    align-items: center;
    margin-top: -5rem;
}
.buttons{
    gap: 1rem;
    margin-left: 2.5rem;
}
.buttons .btn{
    font-size: small;
    padding-left: none;
    /* width: 12rem; */
    align-items: center;

}
.right-h{
    /* display: none; */
    position: absolute;
    align-items: self-end;
    justify-content: center;
    margin-top: 20rem;
    margin-left: 20rem;
    /* left: 5px; */
    transform: scale(0.4);
}

.right-h>button{
    transform: scale(1.4);
    width: 8rem;
    margin-right: 1rem;
    border-radius: 1rem;
    /* transform: scale(0.5); */
}

}