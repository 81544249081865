.Reasons{
    display: flex;
    gap:2rem ;
    padding: 0 2rem;
    /* flex-direction: column; */
}

.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 14rem;
    grid-row: 1/3;
    height: 28rem;
}

.left-r>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    width:  14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-r>:nth-child(4){
    width:  10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
}
.right-r{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}

.right-r>div{
    color: white;
    font-weight: bold;
    font-size: 2rem;
}

.right-r .details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div{
    display: flex;
    gap: 1rem;
    font-size: large;
    align-items: center;
    text-transform: uppercase;
}
.details-r>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;
    gap: 1.5rem;
}
.partners>img{
    width: 3rem;
}

@media screen and  (max-width : 768px){
    .Reasons{
        flex-direction: column;
        margin-top: -16rem;
    }
    .left-r{
        transform: scale(0.45);
        position: relative;
        margin-left: -6.5rem;
    }
    .left-r>:nth-child(2){
        margin-top: -13rem;
        margin-left: -9rem;
        transform: scale(1.5);
    }
    .left-r>:nth-child(1){
        margin-top: 9rem;
    }
    .left-r>:nth-child(3){
        position: absolute;
        margin-top: -12rem;
        width: 22rem;
        margin-bottom: 0.5rem;
        top: 35px;
        height: 13rem;
    }
    .left-r>:nth-child(4){
        position: absolute;
        margin-top: 9rem;
        margin-left: -15rem;
        width: 22rem;
        bottom: 0px;
        left: 182px;
        height: 13rem;
    }

    .right-r{
        margin-top: -6rem;
    }
}