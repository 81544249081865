.Footer-container{
    position: relative;
}
.footer{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    height: 20rem;
    gap: 4rem;
    align-items: center;
    justify-content: center;
}

.social-logo{
    display: flex;
    gap: 4rem;  
}

.social-logo>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 12rem;
}
.social-logo>img:hover{
    background-color: var(--orange);
    transform: scale(1.3);
    border-radius: 10px;
}

.footer-blur-1{
    bottom: 0;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
    right: 20rem;
}
.footer-blur-2{
    bottom: 0;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: orange;
    left:  20rem;
}

@media screen and  (max-width : 768px){
    .Footer-container{
        height: 14rem;
        margin-top: -4rem;
    }
    .footer{
        margin-top: -5rem;
    }
}